<!-- eslint-disable vue/camelcase -->
<template>
  <div class="">
    <TitleWrapper
      :filter-option="false"
      title="SALARY_HISTORY"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="p-7 bg-white w-full rounded-lg mt-1">
      <!-- Personal Information -->
      <div>
        <div
          class="pb-2.5 font-medium font-roboto text-xl text-label-text border-b border-primary-grey"
        >
          Salary Detail
        </div>
        <div class="flex flex-wrap">
          <div class="w-2/4">
            <div class="mt-5 flex font-roboto font-base mb-4">
              <div class="w-40 mr-2">Salary Title:</div>
              <div class="text-menu">
                {{ (detail && detail.title) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
            <div v-if="detail && detail.staff_user" class="mt-5 flex font-roboto font-base mb-4">
              <div class="w-40 mr-2">Staff Name</div>
              <div class="text-menu capitalize">
                {{ `${detail.staff_user.first_name} ${detail.staff_user.last_name}` }}
              </div>
            </div>
            <div class="mt-5 flex font-roboto font-base mb-4">
              <div class="w-40 mr-2">Base Salary:</div>
              <div class="text-menu">
                {{ (detail && detail.base_salary) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
            <div class="mt-5 flex font-roboto font-base mb-4">
              <div class="w-40 mr-2">Gross Salary:</div>
              <div class="text-menu">
                {{ (detail && detail.gross_salary) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
            <div class="mt-5 flex font-roboto font-base mb-4">
              <div class="w-40 mr-2">Net Salary:</div>
              <div class="text-menu">
                {{ (detail && detail.net_salary) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
          </div>
          <div class="w-2/4">
            <div class="mt-5 flex font-roboto font-base mb-4">
              <div class="w-40 mr-2">Extra working unit:</div>
              <div class="text-menu">
                {{
                  (detail && detail.extra_working_unit && detail.extra_working_unit.title) ||
                  GENERAL_CONSTANTS.NOT_APPLICABLE
                }}
              </div>
            </div>
            <div class="mt-5 flex font-roboto font-base mb-4">
              <div class="w-40 mr-2">Extra working Rate:</div>
              <div class="text-menu">
                {{ (detail && detail.extra_working_unit_rate) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
            <div class="mt-5 flex font-roboto font-base mb-4">
              <div class="w-40 mr-2">Extra working quantity:</div>
              <div class="text-menu">
                {{ (detail && detail.extra_working_quantity) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
            <div class="mt-5 flex font-roboto font-base mb-4">
              <div class="w-40 mr-2">Extra working amount:</div>
              <div class="text-menu">
                {{ (detail && detail.extra_working_amount) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
          </div>
        </div>
        <!-- SALARY ALLOWANCES -->
        <div v-if="salaryAllowanceCount" class="mt-7">
          <div
            class="pb-2.5 font-medium font-roboto text-xl text-label-text border-b border-primary-grey"
          >
            Salary Allowances
          </div>
          <TableWrapper v-if="!isLoadingSalaryAllowance">
            <THead>
              <TRHead>
                <TH v-for="(head, index) in tableHeaders" :key="index">
                  {{ head }}
                </TH>
              </TRHead>
            </THead>
            <TBody>
              <TRBody v-for="(salary, index) in salaries_allowances" :key="index">
                <TD>
                  {{
                    (salary.allowance_type && salary.allowance_type.title) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </TD>
                <TD>
                  {{
                    (salary.allowance_type && salary.allowance_type.description) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </TD>
                <TD>
                  {{ salary.amount || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </TD>
              </TRBody>
            </TBody>
          </TableWrapper>
          <div v-else class="mt-16 mb-28">
            <Loader :content="true" />
          </div>
          <Pagination
            v-if="salaryAllowanceCount > 5"
            v-show="!isLoadingSalaryAllowance"
            :record-limit="filteredRecordLimit"
            :max-range="paginationCounts(salaryAllowanceCount, filteredRecordLimit)"
            @filterRecord="_getAllowances"
          />
        </div>
        <!-- SALARY BONUSES -->
        <div v-if="salaryBonusCount" class="mt-7">
          <div
            class="pb-2.5 font-medium font-roboto text-xl text-label-text border-b border-primary-grey"
          >
            Salary Bonuses
          </div>
          <TableWrapper v-if="!isLoadingSalaryBonus">
            <THead>
              <TRHead>
                <TH v-for="(head, index) in tableHeaders" :key="index">
                  {{ head }}
                </TH>
              </TRHead>
            </THead>
            <TBody>
              <TRBody v-for="(salary, index) in salaries_bonuses" :key="index">
                <TD>
                  {{
                    (salary.bonus_type && salary.bonus_type.title) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </TD>
                <TD>
                  {{
                    (salary.bonus_type && salary.bonus_type.description) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </TD>
                <TD>
                  {{ salary.amount || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </TD>
              </TRBody>
            </TBody>
          </TableWrapper>
          <div v-else class="mt-16 mb-28">
            <Loader :content="true" />
          </div>
          <Pagination
            v-if="salaryBonusCount > 5"
            v-show="!isLoadingSalaryBonus"
            :record-limit="filteredRecordLimit"
            :max-range="paginationCounts(salaryBonusCount, filteredRecordLimit)"
            @filterRecord="_getBonuses"
          />
        </div>
        <!-- SALARY DEDUCTION -->
        <div v-if="salaryDeductionCount" class="mt-7">
          <div
            class="pb-2.5 font-medium font-roboto text-xl text-label-text border-b border-primary-grey"
          >
            Salary Deductions
          </div>
          <TableWrapper v-if="!isLoadingSalaryDeduction">
            <THead>
              <TRHead>
                <TH v-for="(head, index) in tableHeaders" :key="index">
                  {{ head }}
                </TH>
              </TRHead>
            </THead>
            <TBody>
              <TRBody v-for="(salary, index) in salaries_deductions" :key="index">
                <TD>
                  {{
                    (salary.deduction_type && salary.deduction_type.title) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </TD>
                <TD>
                  {{
                    (salary.deduction_type && salary.deduction_type.description) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </TD>
                <TD>
                  {{ salary.amount || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </TD>
              </TRBody>
            </TBody>
          </TableWrapper>
          <div v-else class="mt-16 mb-28">
            <Loader :content="true" />
          </div>
          <Pagination
            v-if="salaryDeductionCount > 5"
            v-show="!isLoadingSalaryDeduction"
            :record-limit="filteredRecordLimit"
            :max-range="paginationCounts(salaryDeductionCount, filteredRecordLimit)"
            @filterRecord="_getDeductions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixins from '@src/mixins/general-mixins.js'
import Loader from '@components/BaseComponent/Loader.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TitleWrapper,
    Pagination,
    Loader,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixins],
  data() {
    return {
      GENERAL_CONSTANTS,
      dashboard: 'dashboard',
      salary_id: this.$route.params.id,
      title: 'title',
      detail: {},
      isLoadingSalaryAllowance: false,
      salaryAllowanceCount: 0,
      isLoadingSalaryBonus: false,
      salaryBonusCount: 0,
      isLoadingSalaryDeduction: false,
      salaryDeductionCount: 0,
      tableHeaders: ['Title', 'Description', 'Amount'],
      salaries_allowances: [],
      salaries_bonuses: [],
      salaries_deductions: [],
      filteredRecordLimit: 5,
    }
  },
  page: {
    title: 'Salary Detail',
  },
  created() {
    this._getSalaryDetail()
  },
  methods: {
    async _getSalaryDetail() {
      let [res, err] = await this.getSalaryDetail({ salary_id: this.salary_id })
      this.detail = res.data
      this.salaries_allowances = res.data.salaries_allowances
      this.salaries_bonuses = res.data.salaries_bonuses
      this.salaries_deductions = res.data.salaries_deductions
      this.salaryAllowanceCount = res.data.total_allowaces_count
      this.salaryBonusCount = res.data.total_bonuses_count
      this.salaryDeductionCount = res.data.total_deductions_count
    },

    async _getAllowances(range) {
      this.isLoadingSalaryAllowance = true
      const payload = {
        salary_id: this.salary_id,
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      let [res, err] = await this.getSalaryAllowance(payload)
      this.isLoadingSalaryAllowance = false
      this.salaries_allowances = res.data.records
    },

    async _getBonuses(range) {
      this.isLoadingSalaryBonus = true
      const payload = {
        salary_id: this.salary_id,
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      let [res, err] = await this.getSalaryBonuses(payload)
      this.isLoadingSalaryBonus = false
      this.salaries_bonuses = res.data.records
    },

    async _getDeductions(range) {
      this.isLoadingSalaryDeduction = true
      const payload = {
        salary_id: this.salary_id,
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      let [res, err] = await this.getSalaryDeduction(payload)
      this.isLoadingSalaryDeduction = false
      this.salaries_deductions = res.data.records
    },

    ...mapActions('salary', [
      'getSalaryDetail',
      'getSalaryAllowance',
      'getSalaryBonuses',
      'getSalaryDeduction',
    ]),
  },
}
</script>
